import "./App.css";
import Start from "./Quiz/Start";
import QuizForm from "./Quiz/QuizForm";
import ReportCharts from "./Quiz/ReportCharts";
// import { Waiting } from "./Quiz/Waiting";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Reports from "./Quiz/Reports";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import ReportsCard from "./Quiz/ReportsCard";
import AnswersAll from "./Quiz/AnswersAll";
import JuryReports from "./Quiz/juryReports";

import { Waiting } from "./Quiz/Waiting";

function App() {
  const [id, setId] = useState("");
  useEffect(() => {
    const fetch = async () => {
      const data = await getScreens();
    };
    fetch();
  }, []);

  const getScreens = async () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    setId(c);
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Start eid={id} />} />
          <Route path={`/quiz`} element={<QuizForm urlId={id} />} />
          <Route path="/report/:id/:eid" element={<Reports urlId={id} />} />
          <Route path="/waiting" element={<Waiting id={id} />} />
          <Route path="/checkReports/:id" element={<ReportCharts />} />
          <Route path="/reportcard/:id" element={<ReportsCard/>} />
          <Route path="/answers/:eid" element={<AnswersAll/>}/>
          <Route path="/juryReports/:eid" element={<JuryReports/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
