import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, InputGroup, Row, Progress} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {
    getDoc,
    doc,
    updateDoc,
    collection,
    setDoc,
    serverTimestamp,
    getDocs, query, where,orderBy
} from "firebase/firestore";
import {db, storage} from "../firebase";
import DataTable from "react-data-table-component";

// import config from "../constant/detail.json";

const JuryReports = () => {
    const {eid} = useParams();
    const [loader, setLoader] = useState(true);
    const [ideas, setIdeas] = useState([]);

    const columns = [
        {
            name: (
                <div>
                    <b>Project Name</b>
                </div>
            ),
            selector: (row) => (row.description + " " + row.name),
            minWidth: "60%",
            wrap: true
            // accessor: 'details',
            // style: {overflow: "visible"},
            // maxWidth: 700
            // style: {background: "red"},
        },
        {
            name: <b>Main Jury</b>,
            selector: (row) => row.voteCountSeniorJury,
            minWidth: "5%"
        },
        {
            name: <b>Other Jury</b>,
            selector: (row) => row.voteCountJuniorJury,
            minWidth: "5%"
        },
        {
            name: <b>Weighted Main</b>,
            selector: (row) => (row.voteCountSeniorJury * 0.8 ).toFixed(2),
            minWidth: "5%"
        },
        {
            name: <b>Weighted Other</b>,
            selector: (row) => (row.voteCountJuniorJury * 0.2 ).toFixed(2),
            minWidth: "5%"
        },
        {
            name: <b>Final Vote Count</b>,
            selector: (row) => ((row.voteCountSeniorJury * 0.8) + (row.voteCountJuniorJury * 0.2)).toFixed(2),
            minWidth: "5%"
        }
    ];
    useEffect(() => {
        // loadIdeasDocument().then(result => {
        //         setIdeas(result.sort((a,b) => b.voteCountTotal - a.voteCountTotal));
        //         setLoader(false);
        //     }
        // );


        loadIdeasDocument().then(result => {

                const isFound = result.some(element => {
                    if (element.voteCountJuniorJury*0.2+element.voteCountSeniorJury*0.8 > 0) {
                        return true;
                    }
                    else return false;
                });

                if(isFound){
                    console.log("IF ")
                    setIdeas(result.sort((a,b) => ((b.voteCountSeniorJury * 0.8) + (b.voteCountJuniorJury * 0.2)).toFixed(2) - ((a.voteCountSeniorJury * 0.8) + (a.voteCountJuniorJury * 0.2)).toFixed(2)));
                    setLoader(false);
                }else{
                    console.log("Else")
                    setIdeas(result);
                    setLoader(false);
                }
            }
        );
    }, []);
    const loadIdeasDocument = async () => {
        // console.log(eid)
        // const docRef = collection(db, "ideas");
        // const q = query(docRef,where("eventID","==",eid-1))
        // const ideasData = await getDocs(docRef);
        // const w = ideasData.docs.map((doc) => ({...doc.data()}));
        // console.log(w);
        // return w;


        const docRef = collection(db, "ideas");
        const num=eid
        const q = query(
            docRef,
            where("eventID", "==", num.toString())
            , orderBy("ideaCounter")
        );
        const ideasData = await getDocs(q);
        const w = ideasData.docs.map((doc) => ({...doc.data()}));
        console.log(w);
        return w;
    }


    return (
        <div>
            <>
                {loader ? (
                    <div className="backGround d-flex align-items-center justify-content-center ">
                        <h2 style={{color: "white"}}>Loading...</h2>
                    </div>
                ) : (
                    <>
                        {" "}
                        <Container className="my-3">
                            {/*Button Links*/}
                            <div className="d-flex justify-content-start align-items-center mb-5">
                                <Link to={`/?id=${eid}`} className="btn btn-secondary me-2">
                                    Main
                                </Link>
                                <Link
                                    to={`/checkReports/${eid}`}
                                    className="btn btn-secondary me-2"
                                >
                                    Chart
                                </Link>
                                <Link to={`/answers/${eid}`} className="btn btn-success me-2">
                                    Questions
                                </Link>
                                <Link to={`/reportcard/${eid}`} className="btn btn-primary me-2">
                                    Percentage
                                </Link>
                                <Link to={`/juryReports/${eid}`} className="btn btn-warning me-2 text-white ">
                                    Jury Reports
                                </Link>
                            </div>
                            <Row>
                                <Col>
                                    <h4 className='text-white'>Execution Projects Voting Report</h4>
                                    {ideas.map(idea => (
                                        <div className="p-2 px-5 ideaText">
                                            <div className="text-white">{idea.description+" "} {idea.name} </div>
                                            <Progress value={((idea.voteCountSeniorJury * 0.8) + (idea.voteCountJuniorJury * 0.2)).toFixed(2)} max={175}
                                                      className="bg-transparent">{((idea.voteCountSeniorJury * 0.8) + (idea.voteCountJuniorJury * 0.2)).toFixed(2)}</Progress>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card small className="my-5 ">
                                        <CardHeader className="border-bottom d-flex justify-content-between">
                                            <h4 className="m-0">Execution Projects Voting Calculation Report</h4>
                                        </CardHeader>
                                        <CardBody className="p-0 pb-3">
                                            <DataTable
                                                columns={columns}
                                                data={ideas}
                                                pagination
                                                progressPending={loader}
                                                paginationPerPage={50}
                                                paginationRowsPerPageOptions={[50 , 100 , 150 , 200]}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </>
        </div>
    );
};

export default JuryReports;