import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, InputGroup, Row,} from "reactstrap";

import {Link, useParams} from "react-router-dom";
import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import {db} from "../firebase";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
// import config from "../constant/detail.json";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ReportCharts = () => {
    const {id} = useParams();
    const [users, setUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [userSelections, setUserSelections] = useState({});
    const [armor, setArmor] = useState("");
    const [weapons, setWeapons] = useState("");
    const [sheild, setShield] = useState("");
    const [modal, setModal] = useState("");
    const [industry, setIndustry] = useState("");
    const [inventary, setInventary] = useState("");
    const [configData , setConfigData] = useState([]);
    const [jsonLoad , setJsonLoad] = useState(false);

    const calcPercentage = (value) => {
        if (value)
            return (value / userData?.length) * 100;
        else return 0;
    }
    const calcPerIn = (value) => {
        if (value)
            return ((value / userData?.length) * 100) / 4;
        else return 0;
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                fontColor: "#fff",
                display: true,
                position: "right",
                labels: {
                    color: "white",
                    anchor: "center",
                },
            },
        },
    };
    const baseModels = {
        labels:  jsonLoad && configData.avatarScreen.avatar.map((_avatar) => _avatar.name),
        datasets: [
            {
                data: userSelections.avatars && jsonLoad
                    ? configData.avatarScreen.avatar.map((_avatar) =>
                        calcPercentage(
                            userSelections.avatars[_avatar.name.trim()] ?? 0
                        ).toFixed(1)
                    )
                    : [0],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(87,192,75,0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgb(75,192,77)",
                ],
                datalabels: {
                  color: "white",
                },
            },
        ],
    };
    const armors = {
        labels: jsonLoad && configData.armorScreen.armor.map(
            (_armor) => `${_armor.name.trim()} - ${_armor.desc}`
        ),
        datasets: [
            {
                data: userSelections.armors && jsonLoad
                    ? configData.armorScreen.armor.map((_armor) =>
                        calcPercentage(
                            userSelections.armors[_armor.name.trim()] ?? 0
                        ).toFixed(1)
                    )
                    : [0],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(87,192,75,0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgb(75,192,77)",
                ],
                datalabels: {
                  color: "white",
                },
            },
        ],
    };
    const shield = {
        labels: jsonLoad && configData.shieldScreen.sheild.map(
            (_shield) => `${_shield.name.trim()} - ${_shield.desc}`
        ),
        datasets: [
            {
                data: userSelections.shields && jsonLoad
                    ? configData.shieldScreen.sheild.map((_shield) =>
                        calcPercentage(
                            userSelections.shields[_shield.name.trim()] ?? 0
                        ).toFixed(1)
                    )
                    : [0],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(87,192,75,0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgb(75,192,77)",
                ],
                borderWidth: 1.5,
                datalabels: {
                  color: "white",
                },
            },
        ],
    };
    const weapon = {
        labels: jsonLoad && configData.weaponScreen.weapon.map(
            (_weapon) => `${_weapon.name.trim()} - ${_weapon.desc}`
        ),
        datasets: [
            {
                data: userSelections.weapons && jsonLoad
                    ? configData.weaponScreen.weapon.map((_weapon) =>
                        calcPercentage(
                            userSelections.weapons[_weapon.name.trim()] ?? 0
                        ).toFixed(1)
                    )
                    : [0],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(87,192,75,0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgb(75,192,77)",
                ],
                borderWidth: 1.5,
                datalabels: {
                  color: "white",
                },
            },
        ],
    };
    // const inventory = {
    //     labels: config.Inventory.map(_inventory => _inventory.name.trim()),
    //     datasets: [
    //         {
    //             data: userSelections.inventory
    //                 ? config.Inventory.map(_inventory => calcPerIn(userSelections.inventory[_inventory.name.trim()] ?? 0).toFixed(1))
    //                 : [0],
    //             backgroundColor: [
    //                 "rgba(255, 99, 132, 0.2)",
    //                 "rgba(54, 162, 235, 0.2)",
    //                 "rgba(1, 206, 86, 0.2)",
    //                 "rgba(87,192,75,0.2)",
    //                 "rgba(99,118,222,0.2)",
    //                 "rgba(56,219,177,0.2)",
    //                 "rgba(89,255,175,0.2)",
    //                 "rgba(24,112,236,0.2)",
    //                 "rgba(23,217,65,0.2)",
    //                 "rgba(159,217,23,0.2)",
    //                 "rgba(212,41,18,0.2)",
    //                 "rgba(238, 130, 238,0.2)",
    //                 "rgba(255, 165, 0,1)",
    //                 "rgba(255, 165, 0)",
    //             ],
    //             borderColor: [
    //                 "rgba(255, 99, 132, 0.2)",
    //                 "rgba(54, 162, 235, 0.2)",
    //                 "rgba(255, 206, 86, 0.2)",
    //                 "rgba(87,192,75,0.2)",
    //                 "rgba(99,118,222,0.2)",
    //                 "rgba(60,219,177,0.2)",
    //                 "rgba(0,255,175,0.63)",
    //                 "rgba(24,112,236,0.72)",
    //                 "rgba(23,217,65,0.64)",
    //                 "rgba(159,217,23,0.71)",
    //                 "rgba(212,41,18,0.63)",
    //             ],
    //             datalabels: {
    //                 color: "white",
    //             },
    //         },
    //     ],
    // };

    useEffect(() => {
        console.log(id);
        loadUsers().then((result) => {
            console.log("Then", result);
            setUsers(result);
            setUserData(result);
            setLoader(false);
            console.log(userData);
            console.log(users.length, "length");
        });
        loadDetailsJson().then(result => {
            console.log(result);
            setConfigData(result);
            console.log(result.avatarScreen.avatar);
            setJsonLoad(true);
        });
    }, []);

    useEffect(() => {
        tempFunc();
    }, [users]);

    const loadDetailsJson = async () => {
        const eventDocRef = doc(db, "events", id);
        const eventDocSnap = await getDoc(eventDocRef);
        if (eventDocSnap.exists()) {
            console.log("Document data:", eventDocSnap.data());
            let obj = eventDocSnap.data();
            const detailId = obj.detailsId;
            console.log(detailId);
            const detailDocRef = doc(db, "details", detailId);
            const detailDocSnap = await getDoc(detailDocRef);
            return detailDocSnap.data();
        } else {
            console.log("No such document!");
        }
    }
    const loadUsers = async () => {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("eventID", "==", id), where("attributes.armor", "!=", ""));
        const usersData = await getDocs(q);
        const w = usersData.docs.map((doc) => ({...doc.data()}));
        console.log(w);
        console.log("setting users");
        return w;
    };

    const tempFunc = () => {
        console.log(users);

        const _userSelections = {avatars: {}, shields: {}, weapons: {}, armors: {}, inventory: {}};

        users.forEach(_user => {
            _userSelections.avatars[_user.attributes.baseCharacter.trim()] = _userSelections.avatars[_user.attributes.baseCharacter.trim()] ? _userSelections.avatars[_user.attributes.baseCharacter.trim()] + 1 : 1;
            _userSelections.shields[_user.attributes.shield.trim()] = _userSelections.shields[_user.attributes.shield.trim()] ? _userSelections.shields[_user.attributes.shield.trim()] + 1 : 1;
            _userSelections.weapons[_user.attributes.weapon.trim()] = _userSelections.weapons[_user.attributes.weapon.trim()] ? _userSelections.weapons[_user.attributes.weapon.trim()] + 1 : 1;
            _userSelections.armors[_user.attributes.armor.trim()] = _userSelections.armors[_user.attributes.armor.trim()] ? _userSelections.armors[_user.attributes.armor.trim()] + 1 : 1;

            for (const _key in _user.attributes.inventory) {
                const _inventory = _user.attributes.inventory[_key];
                _userSelections.inventory[_inventory.trim()] = _userSelections.inventory[_inventory.trim()] ? _userSelections.inventory[_inventory.trim()] + 1 : 1;
            }
        });

        console.log(_userSelections);

        setUserSelections(_userSelections);
    };

    useEffect(() => {
        if (armor || sheild || weapons || industry || modal || inventary) {
            let data = userData;
            console.log("test", data);
            console.log("zubair");
            if (armor) {
                if (armor === "Select All") {
                    setArmor("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.attributes.armor
                                .toString()
                                .toLowerCase()
                                .includes(armor.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                }
            }
            if (industry) {
                if (industry === "Select All") {
                    setIndustry("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.industry
                                .toString()
                                .toLowerCase()
                                .includes(industry.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                }
            }
            if (sheild) {
                if (sheild === "Select All") {
                    setShield("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.attributes.shield
                                .toString()
                                .toLowerCase()
                                .includes(sheild.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                }
            }
            if (weapons) {
                if (weapons === "Select All") {
                    setWeapons("");
                } else {
                    data = data.filter((item) => {
                        if (item.attributes.weapon.toString().includes(weapons)) {
                            return item;
                        }
                    });
                }
            }
            if (modal) {
                if (modal === "Select All") {
                    setModal("");
                } else {
                    data = data.filter((item) => {
                        if (item.attributes.baseCharacter.toString().includes(modal)) {
                            return item;
                        }
                    });
                }
            }

            if (inventary) {
                if (inventary === "Select All") {
                    setInventary("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.attributes.inventory[0] === inventary ||
                            item.attributes.inventory[1] === inventary ||
                            item.attributes.inventory[2] === inventary ||
                            item.attributes.inventory[3] === inventary
                        ) {
                            return item;
                        }
                    });
                }
            }

            // setUserData(data);
            setUsers(data);
        } else {
            setUsers(userData);
        }
    }, [inventary, armor, weapons, sheild, modal, industry]);

    return (
        <>
            {loader ? (
                <div className="backGround d-flex align-items-center justify-content-center ">
                    <h2 style={{color: "white"}}>Loading...</h2>
                </div>
            ) : (
                <>
                    <Container className="my-3">
                        <div className="d-flex justify-content-start align-items-center mb-3">
                            <Link to={`/?id=${id}`} className="btn btn-secondary me-2">
                                Main
                            </Link>
                            <Link to={`/checkReports/${id}`} className="btn btn-secondary me-2">
                                Chart
                            </Link>
                            <Link to={`/answers/${id}`} className="btn btn-success me-2">
                                Questions
                            </Link>
                            <Link to={`/reportcard/${id}`} className="btn btn-primary me-2">
                                Percentage
                            </Link>
                            <Link to={`/juryReports/${id}`} className="btn btn-warning me-2 text-white ">
                                Jury Reports
                            </Link>
                        </div>
                        <Row className="mb-3">
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <h4 className="mb-0">Filters</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="d-flex justify-content-between mt-3">
                                            <FormGroup>
                                                <p className="text-filters">Avatar</p>
                                                <InputGroup className="input-group-alternative">
                                                    <select
                                                        onChange={(e) => {
                                                            setModal(e.target.value);
                                                        }}
                                                        style={{
                                                            padding: "6px 12px",
                                                            width: "150px",
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select All</option>
                                                        {jsonLoad && configData.avatarScreen.avatar.map((e) => (
                                                            <option>{e.name}</option>
                                                        ))}
                                                    </select>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <p className="text-filters">Weapon</p>

                                                <InputGroup className="input-group-alternative">
                                                    <select
                                                        onChange={(e) => setWeapons(e.target.value)}
                                                        style={{
                                                            padding: "6px 12px",
                                                            width: "150px",
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select All</option>
                                                        {jsonLoad && configData.weaponScreen.weapon.map((e) => (
                                                            <option>{e.name}</option>
                                                        ))}
                                                    </select>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <p className="text-filters">Shield</p>
                                                <InputGroup className="input-group-alternative">
                                                    <select
                                                        onChange={(e) => {
                                                            setShield(e.target.value);
                                                        }}
                                                        style={{
                                                            padding: "6px 12px",
                                                            width: "150px",
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select All</option>
                                                        {jsonLoad && configData.shieldScreen.sheild.map((e) => (
                                                            <option>{e.name}</option>
                                                        ))}

                                                    </select>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <p className="text-filters">Armor</p>
                                                <InputGroup className="input-group-alternative">
                                                    <select
                                                        onChange={(e) => {
                                                            setArmor(e.target.value);
                                                        }}
                                                        style={{
                                                            padding: "6px 12px",
                                                            width: "150px",
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select All</option>
                                                        {jsonLoad && configData.armorScreen.armor.map((e) => (
                                                            <option>{e.name}</option>
                                                        ))}
                                                    </select>
                                                </InputGroup>
                                            </FormGroup>

                                            {/*<FormGroup>*/}
                                            {/*    <p className="text-filters">Inventory</p>*/}
                                            {/*    <InputGroup className="input-group-alternative">*/}
                                            {/*        <select*/}
                                            {/*            onChange={(e) => {*/}
                                            {/*                setInventary(e.target.value);*/}
                                            {/*            }}*/}
                                            {/*            style={{*/}
                                            {/*                padding: "6px 12px",*/}
                                            {/*                maxWidth: "150px",*/}
                                            {/*            }}*/}
                                            {/*            type="select"*/}
                                            {/*        >*/}
                                            {/*            <option>Select All</option>*/}
                                            {/*            {config.Inventory.map((e)=>(*/}
                                            {/*            <option>{e.name}</option>*/}
                                            {/*           ))}*/}
                                            {/*            */}
                                            {/*        </select>*/}
                                            {/*    </InputGroup>*/}
                                            {/*</FormGroup>*/}

                                            {/*<FormGroup>*/}
                                            {/*    <p className="text-filters">Industry</p>*/}
                                            {/*    <InputGroup className="input-group-alternative">*/}
                                            {/*        <select*/}
                                            {/*            onChange={(e) => {*/}
                                            {/*                setIndustry(e.target.value);*/}
                                            {/*            }}*/}
                                            {/*            style={{*/}
                                            {/*                padding: "6px 12px",*/}
                                            {/*                maxWidth: "150px",*/}
                                            {/*            }}*/}
                                            {/*            type="select"*/}
                                            {/*        >*/}
                                            {/*            <option>Select All</option>*/}
                                            {/*            <option>Finance</option>*/}
                                            {/*            <option>Travel & Hospitality</option>*/}
                                            {/*            <option>Retail & E-commerce</option>*/}
                                            {/*            <option>Technology</option>*/}
                                            {/*            <option>Media & Entertainment</option>*/}
                                            {/*            <option>*/}
                                            {/*                Manufacturing, Transportation & Logistics*/}
                                            {/*            </option>*/}
                                            {/*            <option>Energy, Oil and Gas</option>*/}
                                            {/*            <option>Communication Services</option>*/}
                                            {/*            <option>*/}
                                            {/*                Government & Utilities & Social Institutions*/}
                                            {/*            </option>*/}
                                            {/*            <option>Professional & Educational Services</option>*/}
                                            {/*            <option>Human Resources and Recruitment</option>*/}
                                            {/*            <option>Healthcare & Fitness</option>*/}
                                            {/*            <option>Marketing & Advertising Services</option>*/}
                                            {/*            <option>Telecommunications</option>*/}
                                            {/*        </select>*/}
                                            {/*    </InputGroup>*/}
                                            {/*</FormGroup>*/}

                                            {/* <FormGroup>
              <InputGroup className="input-group-alternative">
                <select
                  onChange={}
                  style={{
                    padding: "6px 12px",
                    marginLeft:"10px",
                    height: "40px",
                    flex: "1 1 auto",
                    borderRadius: "3px",
                  }}
                  type="select"
                >
                  <option>Select All</option>
                  <option>Wall Shield</option>
                  <option>Viking Shield</option>
                  <option>Heater Shield</option>
                </select>
              </InputGroup>
            </FormGroup> */}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="mt-2" md={12}>
                                <h3 className="text-white">Avatar</h3>
                                <p className="text-white">{jsonLoad && configData.avatarScreen.titleDesc}</p>
                                <hr className="text-white"/>
                                <div className="myChart">
                                    <Doughnut data={baseModels} options={options} type=""/>
                                </div>
                            </Col>
                            <Col className="mt-2" md={12}>
                                <h3 className="character_ava">Weapons</h3>
                                <p className="text-white">{jsonLoad && configData.weaponScreen.titleDesc}</p>
                                <hr className="text-white"/>
                                <div className="myChart">
                                    <Doughnut data={weapon} options={options} type=""/>
                                </div>
                            </Col>
                            <Col className="mt-2" md={12}>
                                <h3 className="character_ava">Shield</h3>
                                <p className="text-white">{jsonLoad && configData.shieldScreen.titleDesc}</p>
                                <hr className="text-white"/>
                                <div className="myChart">
                                    <Doughnut data={shield} options={options} type=""/>
                                </div>
                            </Col>
                            <Col className="mt-2" md={12}>
                                <h3 className="character_ava">Armor</h3>
                                <p className="text-white">{jsonLoad && configData.armorScreen.titleDesc}</p>
                                <hr className="text-white"/>
                                <div className="myChart">
                                    <Doughnut data={armors} options={options} type=""/>
                                </div>
                            </Col>
                            {/*<Col className="mt-2" md={12}>*/}
                            {/*    <h3 className="character_ava">Inventory</h3>*/}
                            {/*    <p className="text-white">{config.InventoryDetail.desc}</p>*/}
                            {/*    <hr className="text-white"/>*/}
                            {/*    <div className="myChart">*/}
                            {/*        <Doughnut data={inventory} options={options} type=""/>*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </>
            )}
        </>
    );
};

export default ReportCharts;
