// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCm8sZjWTAKYbRhH3MB-fPfQ8N9gSUT83k",
  authDomain: "cci-c872f.firebaseapp.com",
  projectId: "cci-c872f",
  storageBucket: "cci-c872f.appspot.com",
  messagingSenderId: "1086906720240",
  appId: "1:1086906720240:web:87f14ab1e6fceb09930735"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const admin= firebase.firestore();
const auth = getAuth(app);

export { db, storage, auth };
