import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    InputGroup,
    Row,
} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
// import config from "../constant/detail.json";

const ReportsCard = () => {
    const {id} = useParams();
    const [users, setUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [userSelections, setUserSelections] = useState({});

    const [armor, setArmor] = useState("");
    const [weapons, setWeapons] = useState("");
    const [sheild, setShield] = useState("");
    const [modal, setModal] = useState("");
    const [industry, setIndustry] = useState("");
    const [inventary, setInventary] = useState("");
    const [configData , setConfigData] = useState([]);
    const [jsonLoad , setJsonLoad] = useState(false);

    useEffect(() => {
        console.log(id);
        loadUsers().then((result) => {
            console.log("Then", result);
            setUsers(result);
            setUserData(result);
            console.log(userData);
        });
        loadDetailsJson().then(result => {
            console.log(result);
            setConfigData(result);
            console.log(result.avatarScreen.avatar);
            setJsonLoad(true);
            setLoader(false);
        });
    }, []);
    useEffect(() => {
        tempFunc();
    }, [users]);
    // useEffect(()=>{
    //   console.log(inventoryData);
    // },[inventoryData])
    const loadDetailsJson = async () => {
        const eventDocRef = doc(db, "events", id);
        const eventDocSnap = await getDoc(eventDocRef);
        if (eventDocSnap.exists()) {
            console.log("Document data:", eventDocSnap.data());
            let obj = eventDocSnap.data();
            const detailId = obj.detailsId;
            console.log(detailId);
            const detailDocRef = doc(db, "details", detailId);
            const detailDocSnap = await getDoc(detailDocRef);
            return detailDocSnap.data();
        } else {
            console.log("No such document!");
        }
    }
    const loadUsers = async () => {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("eventID", "==", id), where("attributes.armor", "!=", ""));
        const usersData = await getDocs(q);
        const w = usersData.docs.map((doc) => ({...doc.data()}));
        console.log(w);
        console.log("setting users");
        return w;
    };
    const tempFunc = () => {
        console.log(users);

        const _userSelections = {
            avatars: {},
            shields: {},
            weapons: {},
            armors: {},
            inventory: {},
        };

        users.forEach((_user) => {
            _userSelections.avatars[_user.attributes.baseCharacter.trim()] =
                _userSelections.avatars[_user.attributes.baseCharacter.trim()]
                    ? _userSelections.avatars[_user.attributes.baseCharacter.trim()] + 1
                    : 1;
            _userSelections.shields[_user.attributes.shield.trim()] = _userSelections
                .shields[_user.attributes.shield.trim()]
                ? _userSelections.shields[_user.attributes.shield.trim()] + 1
                : 1;
            _userSelections.weapons[_user.attributes.weapon.trim()] = _userSelections
                .weapons[_user.attributes.weapon.trim()]
                ? _userSelections.weapons[_user.attributes.weapon.trim()] + 1
                : 1;
            _userSelections.armors[_user.attributes.armor.trim()] = _userSelections
                .armors[_user.attributes.armor.trim()]
                ? _userSelections.armors[_user.attributes.armor.trim()] + 1
                : 1;

            for (const _key in _user.attributes.inventory) {
                const _inventory = _user.attributes.inventory[_key];
                _userSelections.inventory[_inventory.trim()] = _userSelections
                    .inventory[_inventory.trim()]
                    ? _userSelections.inventory[_inventory.trim()] + 1
                    : 1;
            }
        });

        setUserSelections(_userSelections);
    };

    const calcPercentage = (value) => {
        console.log(value, userData?.length);
        // const playedUsers = userData?
        if (value) return (value / userData?.length) * 100;
        else return 0;
    };

    const calcPerIn = (value) => {
        if (value) return ((value / userData?.length) * 100) / 4;
        else return 0;
    };

    useEffect(() => {
        if (armor || sheild || weapons || industry || modal || inventary) {
            let data = userData;
            console.log("test", data);
            console.log("zubair");
            if (armor) {
                if (armor === "All") {
                    setArmor("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.attributes.armor
                                .toString()
                                .toLowerCase()
                                .includes(armor.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                }

                // console.log(serachName, data);
            }
            if (industry) {
                if (industry === "All") {
                    setIndustry("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.industry
                                .toString()
                                .toLowerCase()
                                .includes(industry.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                }
            }
            if (sheild) {
                if (sheild === "All") {
                    setShield("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.attributes.shield
                                .toString()
                                .toLowerCase()
                                .includes(sheild.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                }
            }
            if (weapons) {
                if (weapons === "All") {
                    setWeapons("");
                } else {
                    data = data.filter((item) => {
                        if (item.attributes.weapon.toString().includes(weapons)) {
                            return item;
                        }
                    });
                }
            }
            if (modal) {
                if (modal === "All") {
                    setModal("");
                } else {
                    data = data.filter((item) => {
                        if (item.attributes.baseCharacter.toString().includes(modal)) {
                            return item;
                        }
                    });
                }
            }

            if (inventary) {
                if (inventary === "All") {
                    setInventary("");
                } else {
                    data = data.filter((item) => {
                        if (
                            item.attributes.inventory[0] === inventary ||
                            item.attributes.inventory[1] === inventary ||
                            item.attributes.inventory[2] === inventary ||
                            item.attributes.inventory[3] === inventary
                        ) {
                            return item;
                        }
                    });
                }
            }

            // setUserData(data);
            setUsers(data);
        } else {
            setUsers(userData);
        }
    }, [inventary, armor, weapons, sheild, modal, industry]);
    return (
        <div>
            <>
                {loader ? (
                    <div className="backGround d-flex align-items-center justify-content-center ">
                        <h2 style={{color: "white"}}>Loading...</h2>
                    </div>
                ) : (
                    <>
                        {" "}
                        <Container className="my-3">
                            <div className="d-flex justify-content-start align-items-center mb-3">
                                <Link to={`/?id=${id}`} className="btn btn-secondary me-2">
                                    Main
                                </Link>
                                <Link
                                    to={`/checkReports/${id}`}
                                    className="btn btn-secondary me-2"
                                >
                                    Chart
                                </Link>
                                <Link to={`/answers/${id}`} className="btn btn-success me-2">
                                    Questions
                                </Link>
                                <Link to={`/reportcard/${id}`} className="btn btn-primary me-2">
                                    Percentage
                                </Link>
                                <Link to={`/juryReports/${id}`} className="btn btn-warning me-2 text-white ">
                                    Jury Reports
                                </Link>
                            </div>
                            <Row>
                                <Col className="mb-3">
                                    <Card>
                                        <CardHeader>
                                            <h4 className="mb-0">Filters</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Form className="d-flex justify-content-between">
                                                <FormGroup>
                                                    <p className="text-filters">Avatar</p>
                                                    <InputGroup className="input-group-alternative">
                                                        <select
                                                            onChange={(e) => {
                                                                setModal(e.target.value);
                                                            }}
                                                            style={{
                                                                padding: "6px 12px",
                                                                width: "150px",
                                                            }}
                                                            type="select"
                                                        >
                                                            <option>All</option>
                                                            {jsonLoad && configData.avatarScreen.avatar.map((e) => (
                                                                <option>{e.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <p className="text-filters">Weapon</p>
                                                    <InputGroup className="input-group-alternative">
                                                        <select
                                                            onChange={(e) => setWeapons(e.target.value)}
                                                            style={{
                                                                padding: "6px 12px",
                                                                width: "150px",
                                                            }}
                                                            type="select"
                                                        >
                                                            <option>All</option>
                                                            {jsonLoad && configData.weaponScreen.weapon.map((e) => (
                                                                <option>{e.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <p className="text-filters">Shield</p>
                                                    <InputGroup className="input-group-alternative">
                                                        <select
                                                            onChange={(e) => {
                                                                setShield(e.target.value);
                                                            }}
                                                            style={{
                                                                padding: "6px 12px",
                                                                width: "150px",
                                                            }}
                                                            type="select"
                                                        >
                                                            <option>All</option>
                                                            {jsonLoad && configData.shieldScreen.sheild.map((e) => (
                                                                <option>{e.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <p className="text-filters">Armor</p>
                                                    <InputGroup className="input-group-alternative">
                                                        <select
                                                            onChange={(e) => {
                                                                setArmor(e.target.value);
                                                            }}
                                                            style={{
                                                                padding: "6px 12px",
                                                                width: "150px",
                                                            }}
                                                            type="select"
                                                        >
                                                            <option>All</option>
                                                            {jsonLoad && configData.armorScreen.armor.map((e) => (
                                                                <option>{e.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="mb-3">
                                    <Card>
                                        <CardHeader>
                                            <h4 className="mb-0">Avatars</h4>
                                            <p className="small mb-0">{jsonLoad && configData.avatarScreen.titleDesc}</p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {jsonLoad && configData.avatarScreen.avatar.map((_avatar) => (
                                                    <Col md={4}>
                                                        <div className="d-flex justify-content-start align-items-start">
                                                            <img
                                                                src={_avatar.img}
                                                                alt=""
                                                                style={{maxWidth: "100px"}}
                                                            />
                                                            <div className="ms-3">
                                                                <h4 className="mb-1">
                                                                    {calcPercentage(
                                                                        userSelections.avatars[
                                                                            _avatar.name.trim()
                                                                            ] ?? 0
                                                                    ).toFixed(1)}
                                                                    %
                                                                </h4>
                                                                <h6 className="mb-0">{_avatar.name}</h6>
                                                                <p className="small text-muted mb-1">
                                                                    {_avatar.desc}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} className="mb-3">
                                    <Card>
                                        <CardHeader>
                                            <h4 className="mb-0">Weapons</h4>
                                            <p className="small mb-0">{jsonLoad && configData.weaponScreen.titleDesc}</p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {jsonLoad && configData.weaponScreen.weapon.map((_weapon) => (
                                                    <Col md={4}>
                                                        <div className="d-flex justify-content-start align-items-start">
                                                            <img
                                                                src={_weapon.img}
                                                                alt=""
                                                                style={{maxWidth: "100px"}}
                                                            />
                                                            <div className="ms-3">
                                                                <h4 className="mb-1">
                                                                    {calcPercentage(
                                                                        userSelections.weapons[
                                                                            _weapon.name.trim()
                                                                            ] ?? 0
                                                                    ).toFixed(1)}
                                                                    %
                                                                </h4>
                                                                <h5 className="mb-0">{_weapon.name}</h5>
                                                                <p className="small text-muted mb-1">
                                                                    {_weapon.desc}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} className="mb-3">
                                    <Card>
                                        <CardHeader>
                                            <h4 className="mb-0">Shields</h4>
                                            <p className="small mb-0">{jsonLoad && configData.shieldScreen.titleDesc}</p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {jsonLoad && configData.shieldScreen.sheild.map((_shield) => (
                                                    <Col md={4}>
                                                        <div className="d-flex justify-content-start align-items-start">
                                                            <img
                                                                src={_shield.img}
                                                                alt=""
                                                                style={{maxWidth: "100px"}}
                                                            />
                                                            <div className="ms-3">
                                                                <h4 className="mb-1">
                                                                    {calcPercentage(
                                                                        userSelections.shields[
                                                                            _shield.name.trim()
                                                                            ] ?? 0
                                                                    ).toFixed(1)}
                                                                    %
                                                                </h4>
                                                                <h6 className="mb-0">{_shield.name}</h6>
                                                                <p className="small text-muted mb-1">
                                                                    {_shield.desc}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} className="mb-3">
                                    <Card>
                                        <CardHeader>
                                            <h4 className="mb-0">Armors</h4>
                                            <p className="small mb-0">{jsonLoad && configData.armorScreen.titleDesc}</p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {jsonLoad && configData.armorScreen.armor.map((_armor) => (
                                                    <Col md={4}>
                                                        <div className="d-flex justify-content-start align-items-start">
                                                            <img
                                                                src={_armor.img}
                                                                alt=""
                                                                style={{maxWidth: "100px"}}
                                                            />
                                                            <div className="ms-3">
                                                                <h4 className="mb-1">
                                                                    {calcPercentage(
                                                                        userSelections.armors[_armor.name.trim()] ??
                                                                        0
                                                                    ).toFixed(1)}
                                                                    %
                                                                </h4>
                                                                <h6 className="mb-0">{_armor.name}</h6>
                                                                <p className="small text-muted mb-1">
                                                                    {_armor.desc}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                {/*<Col sm={12} className="mb-3">*/}
                                {/*    <Card>*/}
                                {/*        <CardHeader>*/}
                                {/*            <h4 className="mb-0">Inventory</h4>*/}
                                {/*            <p className="small mb-0">{config.InventoryDetail.des}</p>*/}
                                {/*        </CardHeader>*/}
                                {/*        <CardBody>*/}
                                {/*            <Row>*/}
                                {/*                {config.Inventory.map((_inventory) => (*/}
                                {/*                    <Col md={3} className="mb-3">*/}
                                {/*                        <div className="d-flex justify-content-start align-items-start">*/}
                                {/*                            <img*/}
                                {/*                                src={_inventory.img}*/}
                                {/*                                alt=""*/}
                                {/*                                style={{maxWidth: "100px"}}*/}
                                {/*                            />*/}
                                {/*                            <div className="ms-3">*/}
                                {/*                                <h4 className="mb-1">*/}
                                {/*                                    {calcPerIn(*/}
                                {/*                                        userSelections.inventory[*/}
                                {/*                                            _inventory.name.trim()*/}
                                {/*                                            ] ?? 0*/}
                                {/*                                    ).toFixed(1)}*/}
                                {/*                                    %*/}
                                {/*                                </h4>*/}
                                {/*                                <h6 className="mb-0">{_inventory.name}</h6>*/}
                                {/*                                /!*<p className="small text-muted mb-1">{_inventory.des}</p>*!/*/}
                                {/*                            </div>*/}
                                {/*                        </div>*/}
                                {/*                    </Col>*/}
                                {/*                ))}*/}
                                {/*            </Row>*/}
                                {/*        </CardBody>*/}
                                {/*    </Card>*/}
                                {/*</Col>*/}
                            </Row>
                        </Container>
                    </>
                )}
            </>
        </div>
    );
};

export default ReportsCard;
