import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {db} from "../firebase";
import {Link, useParams} from "react-router-dom";
import {collection, doc, getDoc, getDocs, orderBy, query, where,} from "firebase/firestore";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import {Bar} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AnswersAll = () => {
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState("");
  const { eid } = useParams();
  useEffect(() => {
    console.log("useEffect");
    setLoading("pending");
    tempFunc().then(result => {
      setQuestions(result);
      getAnswers(result).then(result => {
        setLoading(null);
      })
    })
    // const fetch = async () => {
    //   try {
    //     setLoading("pending");
    //     const data = await tempFunc();
    //     setQuestions(data);
    //     await getAnswers();
    //     setLoading(null);
    //   } catch (e) {
    //     setLoading(null);
    //   }
    // };
    // fetch();
    // getAnswers();
  }, []);
  useEffect(()=>{
    console.log(answers);
  },[answers])

  const getAnswers = async (reults) => {
    let answersMap ={};
    console.log("get answers")
    for (const question of reults) {
      console.log("Inside loop")
      const docRef = collection(db, "responses");
      const q = query(
          docRef,
          where("eventID", "==", eid),
          where("questionId","==",question.id)
      );
      const responseData = await getDocs(q);
      const w = responseData.docs.map((doc) => ({...doc.data()}));
      console.log(w);
      const opt1 = w.filter(item => item.userAnswer === item.options[0]);
      const opt2 = w.filter(item => item.userAnswer === item.options[1]);
      const opt3 = w.filter(item => item.userAnswer === item.options[2]);
      const opt4 = w.filter(item => item.userAnswer === item.options[3]);
      answersMap = {...answersMap , [question.id]: {option1:opt1.length,option2:opt2.length,option3:opt3.length,option4:opt4.length}}
      console.log(answersMap);
      console.log(opt1.length);
      // setAnswers({
      //   [question.id]: {
      //     option1: opt1.length,
      //     option2: opt2.length,
      //     option3: opt3.length,
      //     option4: opt4.length
      //   }
      // });
    }
    setAnswers(answersMap);
    // console.log(answersMap);
    // setAnswers({option1:opt1.length,option2:opt2.length,option3:opt3.length,option4:opt4.length})

  };

  async function tempFunc() {
    const questionsRef = collection(db, "questions");
    const q = query(
      questionsRef,
      where("eventID", "==", eid),
      orderBy("sequence", "asc")
    );
    const screens = await getDocs(q);
    let dataarr = [];
    screens.forEach((doc) => {
      let obj = doc.data();
      obj = { ...obj, id: doc.id };
      dataarr.push(obj);
    });
    console.log(dataarr);
    return dataarr;
  }

  //   const getQuestions = async () => {
  //     const question1 = doc(db, "questions", id);
  //     const questionSnap = await getDoc(question1);
  //     if (questionSnap.exists()) {
  //       console.log("Document data:", questionSnap.data());
  //       let obj = questionSnap.data();
  //       console.log(obj);
  //       setQuestion(obj);
  //     } else {
  //       console.log("No such document!");
  //     }
  //   };

  const options = {
    plugins: {
      legend: {
        display: false,
   
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: "white",
          borderColor: "white"
        },
        beginAtZero: true,
        ticks: {
          color: "white",
          font: {
            size: 0,
          },
        },
      },
      y: {
        grid: {
          display: false,
          color: "white",
          borderColor: "white"
        },
        beginAtZero: true,
        ticks: {
          color: "white",
          font: {
            size: 20,
            
          },

          stepSize: 1,
        },
      },
    }
  };
  let data = {};
  //   const data = {
  //     datasets: [
  //       {
  //         label: "# of Results",
  //         data: typeof answers !== "undefined" && [
  //           answers[1],
  //           answers[2],
  //           answers[3],
  //           answers[4],
  //         ],
  //         backgroundColor: [
  //           "#4F9F30",
  //           "#3977CB",
  //           "#C07E2F",
  //           "#A82929",
  //           "rgba(153, 102, 255, 1)",
  //           "rgba(255, 159, 64, 1)",
  //         ],
  //         borderColor: ["#4F9F30", "#3977CB", "#C07E2F", "#A82929"],
  //         borderWidth: 3,
  //       },
  //     ],
  //   };

  return (
    <>
      {loading === "pending" ? (
        <div className=" backGround d-flex align-items-center justify-content-center">
          <h2
            className="text-center"
            style={{ color: "white", marginTop: "20px" }}
          >
            Loading...
          </h2>
        </div>
      ) : (
          <>
            <Container className={"mt-3"}>
              <div className="d-flex justify-content-start align-items-center mb-3">
                <Link to={`/?id=${eid}`} className="btn btn-secondary me-2">
                  Main
                </Link>
                <Link to={`/checkReports/${eid}`} className="btn btn-secondary me-2">
                  Chart
                </Link>
                <Link to={`/answers/${eid}`} className="btn btn-success me-2">
                  Questions
                </Link>
                <Link to={`/reportcard/${eid}`} className="btn btn-primary me-2">
                  Percentage
                </Link>
                <Link to={`/juryReports/${eid}`} className="btn btn-warning me-2 text-white ">
                  Jury Reports
                </Link>
              </div>
            </Container>
            {questions.map((question) => (
                <>
                  {console.log(answers[question.id])}
                  <div style={{}} className="">
                    <Row style={{marginTop: "55px"}} className="img-bg height">
                      <Col
                          className={"d-flex  align-items-center padding1"}
                          md={2}
                          sm={12}
                      />
                      <Col
                    className={
                      "d-flex justify-content-center  align-items-center"
                    }
                    md={8}
                    sm={12}
                  >
                    <p className="question">{question.question}</p>
                  </Col>
                  <Col
                    style={{ height: "70px" }}
                    className={
                      "d-flex justify-content-end  align-items-center padding2"
                    }
                    md={2}
                    sm={12}
                  >
                    <div>
                      <Link
                        to={`/waiting/?id=${eid}`}
                        className="text-decoration-none"
                      >
                        {/* <button className="btn-skip">Next</button> */}
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={12} className="d-flex justify-content-center ">
                    <div>
                      {/*<img height={"2px"} src="/images/lines.png"/>*/}
                      <p className="results_text">Results</p>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-1">
                  <Col md={5}>
                    <Bar
                      data={
                        (data = {
                            labels: typeof question.options !== "undefined" && [
                                question.options[0],
                                question.options[1],
                                question.options[2],
                                question.options[3],
                              ],
                          datasets: [
                            {
                              label: "# of Results",
                              data: typeof answers !== "undefined"? typeof  answers[question.id]!=="undefined"&& [
                                answers[question.id]["option1"],
                                answers[question.id]["option2"],
                                answers[question.id]["option3"],
                                answers[question.id]["option4"],
                              ]:"",
                              backgroundColor: [
                                "#4F9F30",
                                "#3977CB",
                                "#C07E2F",
                                "#A82929",
                                "rgba(153, 102, 255, 1)",
                                "rgba(255, 159, 64, 1)",
                              ],
                              borderColor: [
                                "#4F9F30",
                                "#3977CB",
                                "#C07E2F",
                                "#A82929",
                              ],
                              datalabels: {
                                color: "white",
                            },
                              borderWidth: 3,
                            },
                            
                          ],
                        })
                      }
                      options={options}
                    />
                    {/* <Link to={`/checkReports/${eid}`}>
                                <Button className="float-end mt-5"> Reports </Button>
                            </Link> */}
                  </Col>
                </Row>

                <Container >
                  <Row>
                    <Col md={10} className="mx-auto">
                      <Row>
                        <Col
                          className="options-pos d-flex align-items-center justify-content-center"
                          md={6}
                        >
                          <div className="center">
                            <p className="text text-center">
                              {typeof question["options"] !== "undefined" &&
                                question["options"][0]}
                            </p>
                          </div>
                        </Col>
                        <Col
                          md={6}
                          className="options-pos1 d-flex align-items-center justify-content-center"
                        >
                          <div className="center">
                            <p className="text text-center">
                              {typeof question["options"] !== "undefined" &&
                                question["options"][1]}
                            </p>
                          </div>
                        </Col>

                        <Col
                          className="options-pos2 d-flex align-items-center justify-content-center"
                          md={6}
                        >
                          <div className="center">
                            <p className="text text-center">
                              {typeof question["options"] !== "undefined" &&
                                question["options"][2]}
                            </p>
                          </div>
                        </Col>
                        <Col
                          md={6}
                          className=" options-pos3 d-flex align-items-center justify-content-center"
                        >
                          <div className="center">
                            <p className="text text-center">
                              {typeof question["options"] !== "undefined" &&
                                question["options"][3]}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          ))}
        </>
      )}
    </>
  );
};

export default AnswersAll;
