import React, {useEffect, useState} from "react";
import "./Start.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {db} from "../firebase";
import {Link, useNavigate} from "react-router-dom";

export default function Start({questions, eid, index}) {
  const [questionData, setQuestionData] = useState([]);
  const [ic, setIc] = useState();
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(null);
  

  let navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading("pending");
        const index = await getIndex();
        const question = await getQuestion(index);
        setQuestionData(question);
        setLoading(null);
      } catch (e) {
        setLoading(null);
      }
    };
    fetch();
  }, []);

  const getQuestion = async (index) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    const questionsRef = collection(db, "questions");
    const q = query(
      questionsRef,
      where("eventID", "==", c),
      where("sequence", "==", parseInt(index))
    );

    const screens = await getDocs(q);
    let dataarr = [];
    screens.forEach((doc) => {
      let obj = doc.data();
      obj = { ...obj, id: doc.id };
      dataarr.push(obj);
    });
    return dataarr;
  };

  const updateCurrent = async () => {
    setLoading("pending");
    const Ref = doc(db, "increment", eid);
    const washingtonRef = doc(db, "events", eid);
    if (typeof questionData[0] === "undefined") {
      console.log("else");
      await updateDoc(Ref, {
        i: 1,
      });
      navigate(`/waiting/?id=${eid}`);
      setChange(!change);
    }

    await updateDoc(washingtonRef, {
      currentQuestion: questionData[0].id,
      didEventExpire: false,
      timer: parseInt(questionData[0].timer),
      timestamp: serverTimestamp(),
    });

    navigate(`/quiz/?id=${eid}`);
    if (typeof questionData[0].id !== "undefined") {
      console.log("in if()");
      // console.log(ic, questions.length);
      await updateDoc(Ref, {
        i: increment(1),
      });
    }
  };

  const getIndex = async () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    const docRef = doc(db, "increment", c);
    console.log(c);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Sequence:", docSnap.data().i);
      setIc(docSnap.data().i);
      // setCountTimer(questions[docSnap.data().i]["timer"])
      return docSnap.data().i;
    } else {
      console.log("No such document!");
    }
  };

  return (
    <>

      <div className="backGround">
        {loading === "pending" ? (
          <div className="backGround d-flex align-items-center justify-content-center">
            <h2 style={{ color: "white" }}>Loading...</h2>
          </div>
        ) : (
            <>
              <div className=" container d-flex justify-content-start align-items-center pt-2 mr-3">
                <Link to={`/checkReports/${eid}`} className="btn btn-secondary me-2">
                  Chart
                </Link>
                <Link to={`/answers/${eid}`} className="btn btn-success me-2">
                  Questions
                </Link>
                <Link to={`/reportcard/${eid}`} className="btn btn-primary me-2">
                  Percentage
                </Link>
                <Link to={`/juryReports/${eid}`} className="btn btn-warning me-2 text-white ">
                  Jury Reports
                </Link>
              </div>
              <div
                  style={{height: "63%"}}
                  className="col-sm-12 d-flex align-items-center justify-content-center"
              >
                <img className="img_set" src="/images/get-ready.png"/>
              </div>
            <div>

              <div
                style={{ height: "180px" }}
                className=" col d-flex align-items-center justify-content-center"

              >
                <img className="img_set1" src="/images/start2.png" onClick={updateCurrent}/>
              </div>
              <div style={{marginTop:"200px"}}>{ic}:{questionData[0]&&questionData[0].question}</div>


            </div>
         
          </>
        )}
      </div>

    </>
  );
}
